import { useEffect, useState } from "react"
import { Loader } from "../components/loader"
import { DataGrid } from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import { useNavigate } from "react-router-dom";
import Axios from 'axios'
// import { refreshAuthToken } from "./AdminDashboard";

const ListReels = ({ reels, columns,isAdmin }) => {

    const navigate = useNavigate();


    return <>
        <h3>Reels page</h3>
        <DataGrid
            onRowClick={(props) => {
                navigate((isAdmin?'/admin':'')+"/reels/edit/" + props.row.itemid)
            }}
            columns={columns}
            rows={reels}
            initialState={{
                pagination: {
                    paginationModel: { page: 0, pageSize: 5 },
                },
            }}
            pageSizeOptions={[5, 10]}
        />
    </>
}

export const ReelList = ({ userID, BASE_URL,isAdmin ,user}) => {
    const [reels, setReels] = useState([])
    const [columns, setColumns] = useState([])
    const [loading, setLoading] = useState(false)

    const fetchReels = async () => {
        try {
            setLoading(true)
            const headers = {
                Authorization: `Bearer ${user.accessToken}`,
              };
              
            const response = (await Axios.get(`${BASE_URL}/api/seller/addedReels/${userID}`,{headers})).data;
            setLoading(false)
            setReels(response?.reels)
            setColumns(response?.columns)
        }
        catch (err) {
            console.log(err);
        }
    }

    useEffect(() => {
        // refreshAuthToken()
        fetchReels()
    }, [])

    return <>
        <Box p={2}>
            <ListReels reels={reels} columns={columns} isAdmin={isAdmin} />
        </Box>
    </>
}