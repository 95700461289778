import React, { useState } from 'react';
import { Card, Box, TextField, Button, Typography, Autocomplete } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import BackButton from './BackButton';
import axios from 'axios';
import LoadingScreen from './LoadingScreen';

const AddProductForm = ({ userID, BASE_URL, setShowSnackbar, setSnackbarMessage, setSnackbarSeverity,isAdmin,user }) => {
    const [productInfo, setProductInfo] = useState({
        name: '',
        description: '',
        // category: '',
        price: '',
        tags: [],
    });
    const [imageFile, setImageFile] = useState(null);
    const handleImageChange = (event) => {
        setImageFile(event.target.files[0]);
    };
    const handleChange = (e) => {
        const { name, value } = e.target;
        setProductInfo((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleTagsChange = (event, newValue) => {
        setProductInfo((prevData) => ({
            ...prevData,
            tags: newValue,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const formData = new FormData();
            formData.append('name', productInfo.name);
            formData.append('description', productInfo.description);
            formData.append('price', productInfo.price);
            productInfo.tags.map((tag) => {
                formData.append('tags', tag);
            })
            formData.append('image', imageFile);

            await axios.post(`${BASE_URL}/api/seller/addProduct/${userID}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${user.accessToken}`,
                },
            });

            setSnackbarMessage('Product added successfully');
            setSnackbarSeverity('success');
            setShowSnackbar(true);

            // Clear form fields after successful submission
            setProductInfo({
                name: '',
                description: '',
                // category: '',
                price: '',
                tags: [],
            });
        } catch (error) {
            console.error('Error adding product:', error);
            setSnackbarMessage('Error adding product - '+error.response?.data?.message||error?.message);
            setSnackbarSeverity('error');
            setShowSnackbar(true);
        }
    };
    if(!userID) return <LoadingScreen></LoadingScreen>
    return (<>
        <Card style={{ maxWidth: 1000, margin: 'auto', marginTop: '50px' }} variant="outlined" align="left">
            <Box p={2}>
                <Typography variant="h5">Add Product</Typography>
                <TextField
                    name="name"
                    label="Product Name"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    value={productInfo.name}
                    onChange={handleChange}
                />
                <TextField
                    name="description"
                    label="Description"
                    variant="outlined"
                    fullWidth
                    multiline
                    rows={4}
                    margin="normal"
                    value={productInfo.description}
                    onChange={handleChange}
                />
                {/* <TextField
                    name="category"
                    label="Category"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    value={productInfo.category}
                    onChange={handleChange}
                /> */}
                <label htmlFor="imageFile">
                    <input
                        type="file"
                        id="imageFile"
                        name="imageFile"
                        accept="image/*"
                        style={{ display: 'none' }}
                        onChange={handleImageChange}
                    />
                    <Button
                        variant="contained"
                        color="secondary"
                        component="span"
                        startIcon={<CloudUploadIcon />}
                    >
                        Upload Image
                    </Button>
                </label>
                <TextField
                    name="price"
                    label="Price"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    value={productInfo.price}
                    onChange={handleChange}
                />
                <Autocomplete
                    multiple
                    id="tags"
                    options={['electronics', "fashion", "mens", "mobile", "best"]}
                    freeSolo
                    value={productInfo.tags}
                    onChange={handleTagsChange}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            name="tags"
                            label="Tags"
                            variant="outlined"
                            fullWidth
                            margin="normal"
                        />
                    )}
                />
                <Box mt={2} mb={1}>
                    <Button variant="contained" color="primary" onClick={handleSubmit}>
                        Add Product
                    </Button>
                </Box>
            </Box>
        </Card>
    </>
    );
};

export default AddProductForm;
