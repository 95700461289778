import React, { useState ,useEffect} from 'react';
import axios from 'axios'

import LoadingScreen from './LoadingScreen';
import { Button, TextField, Select, MenuItem, FormControl, InputLabel, Box, Typography, Modal, Backdrop, Fade } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
const ResourceUploadForm = ({ onClose, productId,  variants, SelectedColor, setSelectedColor, userID, isAdmin,user,BASE_URL, setShowSnackbar, setSnackbarMessage, setSnackbarSeverity }) => {
    const [image, setImage] = useState(null);
    useEffect(()=>{},[userID])
    if(!userID) return <LoadingScreen></LoadingScreen>
    const handleImageChange = (event) => {
        setImage(event.target.files[0]);
    };

    const handleSubmit = async () => {
        try {
            const formData = new FormData();
            formData.append('image', image);
            formData.append('color', SelectedColor);

            // Make API request to upload resource and associate with variant
            await axios.put(`${BASE_URL}/api/seller/uploadResource/${productId}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${user.accessToken}`,
                },
            });
            setSnackbarMessage('Resource added successfully');
            setSnackbarSeverity('success');
            setShowSnackbar(true);
            window.location.reload()

            onClose(); // Close the modal after successful upload
        } catch (error) {
            setSnackbarMessage('Error in adding new resource');
            setSnackbarSeverity('error');
            setShowSnackbar(true);
            console.error('Error uploading resource:', error);
        }
    };

    return (
        <Modal
            open={true}
            onClose={onClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={true}>
                <Box sx={styles.modalContent}>
                    <Typography variant="h6" gutterBottom>
                        Upload Resource
                    </Typography>
                    <label htmlFor="imageFile">
                        <input
                            type="file"
                            id="imageFile"
                            name="imageFile"
                            accept="image/*"
                            style={{ display: 'none' }}
                            onChange={handleImageChange}
                        />
                        <Button
                            variant="contained"
                            color="secondary"
                            component="span"
                            startIcon={<CloudUploadIcon />}
                        >
                            Upload Image
                        </Button>
                    </label>
                    {/* <input type="file" accept="image/*" onChange={} style={styles.fileInput} /> */}
                    <FormControl variant="outlined" style={styles.variantSelect}>
                        <InputLabel>Choose Color</InputLabel>
                        <Select
                            value={SelectedColor}
                            onChange={(e) => setSelectedColor(e.target.value)}
                        >
                            {Array.from(new Set(variants.map(o => o.color))).map((color) => (
                                <MenuItem key={color} value={color}>
                                    {color}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <Button variant="contained" color="primary" onClick={handleSubmit} style={styles.uploadButton}>
                        Upload
                    </Button>
                    <Button variant="outlined" color="primary" onClick={onClose} style={styles.cancelButton}>
                        Cancel
                    </Button>
                </Box>
            </Fade>
        </Modal>
    );
};

const styles = {
    modalContent: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'white',
        boxShadow: 24,
        p: 4,
        outline: 'none',
        width: '300px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '20px',
    },
    fileInput: {
        width: '100%',
    },
    variantSelect: {
        width: '100%',
    },
    uploadButton: {
        width: '100%',
    },
    tagname: {
        color: 'gray',
        marginInline: '4px',
    },
    cancelButton: {
        marginTop: '10px',
    },
};

export default ResourceUploadForm;
