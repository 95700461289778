import { useEffect, useState } from "react"
import Card from '@mui/material/Card';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import axios from 'axios'
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import { textAlign } from '@mui/system';
import DeleteConfirmModal from "../components/DeleteConfirmModal";
import { TextField, Button, Checkbox, Modal } from '@mui/material';
import { useNavigate, useParams } from "react-router-dom";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Video from "../components/Video";
import DeleteIcon from '@mui/icons-material/Delete';
import BackButton from "../components/BackButton";


const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(0),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));



export const ReelPage = ({ userID, BASE_URL, setShowSnackbar, setSnackbarMessage, setSnackbarSeverity, isAdmin, user }) => {
    const { reelId } = useParams();
    const [reelData, setReelData] = useState([])
    const [products, setProducts] = useState([])
    const [allProducts, setAllProducts] = useState([])
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const navigate = useNavigate()

    useEffect(() => {
        const fetch_reel = async () => {
            try {
                const headers = {
                    Authorization: `Bearer ${user?.accessToken}`,
                };
                const response = (await axios.get(`${BASE_URL}/api/seller/reels/${reelId}/${userID}`, { headers })).data;
                setProducts(response.products)
                setAllProducts(response.all_products)
                setReelData(response)

            } catch (error) {
                setSnackbarMessage(error?.response?.message || error.message);
                setSnackbarSeverity('error');
                setShowSnackbar(true);
                console.error('Error fetching reel info:', error);
                navigate((isAdmin ? '/admin' : '') + `/reels/list`)
            }
        }
        fetch_reel()
    }, [])
    const deleteReel = () => {
        setDeleteModalOpen(true)
    }
    const attachProduct = async (reelId, productId) => {
        try {
            // console.log(user.accessToken)
            const headers = {
                Authorization: `Bearer ${user.accessToken}`,
                'Content-Type': 'application/json',
            };
            const data = await axios.post(`${BASE_URL}/api/seller/attachProduct`, { headers, 'reel': reelId, 'product': productId }, { headers });
            var item = data?.data?.product
            item['id'] = products.length
            setProducts([...products, item])
            setSnackbarMessage('Product attached successfully');
            setSnackbarSeverity('success');
            setShowSnackbar(true);

            // Clear form fields after successful submission
        } catch (error) {
            let msg = error?.response?.data?.message || error?.response?.data?.error
            console.error('Error attaching product:', msg);
            setSnackbarMessage('Error :- ' + msg);
            setSnackbarSeverity('error');
            setShowSnackbar(true);
        }

    }

    const AddProducts = () => {
        const [chosenProduct, setChosenProduct] = useState('')

        if (allProducts) {
            return <>
                <Box align="left" pt={2}>
                    <InputLabel id="demo-simple-select-label">Add Products</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={chosenProduct}
                        label="Products"
                        autoWidth
                        onChange={(event) => {
                            setChosenProduct(event.target.value);
                        }}
                    >
                        {allProducts.map((item, index) => <MenuItem value={item._id.toString()}>{item.name}</MenuItem>)}
                    </Select>
                    <Box mt={2}>
                        <Button variant="contained" onClick={() => attachProduct(reelId, chosenProduct)}>Add</Button>
                    </Box>
                </Box>


            </>
        }

    }

    const VideoPlace = () => {

        if (reelData) {
            return <>
                <Video VideoUrl={reelData.videoUrl} height={'auto'} />
                <Card variant="outlined" align="left">
                    <Box p={2}>
                        <h3>{reelData.title}</h3>
                        <p>{reelData.description1}</p>
                    </Box>
                </Card>
            </>
        }

    }


    const ListProducts = () => {
        const navigate = useNavigate();
        const handleDeleteClick = (id) => async () => {
            const pid = products[id]._id

            // make request to backend to  deattach product 
            try {
                const headers = {
                    Authorization: `Bearer ${user.accessToken}`,
                };

                // Make API request to upload resource and associate with variant
                await axios.delete(`${BASE_URL}/api/seller/deAttach/${reelId}/${pid}`, { headers });
                setSnackbarMessage(`Deattached successfully`);
                setSnackbarSeverity('success');
                setShowSnackbar(true);
                setProducts(products.filter((row) => row.id !== id));
            } catch (error) {
                setSnackbarMessage('Error in removing. Please try again later.');
                setSnackbarSeverity('error');
                setShowSnackbar(true);
                console.error('Error ', error);
            }
        };
        if (products) {
            const columns = [
                {
                    field: 'actions',
                    type: 'actions',
                    headerName: 'Actions',
                    width: 80,
                    cellClassName: 'actions',
                    getActions: ({ id }) => {
                        return [
                            <GridActionsCellItem
                                icon={<DeleteIcon />}
                                label="Delete"
                                onClick={handleDeleteClick(id)}
                                color="inherit"
                            />,
                        ];
                    },
                },
                { field: 'id', headerName: 'ID', width: 10 },
                { field: 'name', headerName: 'Name' },
                { field: 'price', headerName: 'Price' },
                { field: 'description', headerName: 'Description', width: 500 },
            ]

            return <>

                <div style={{ position: 'relative' }}>

                    <h3>Attached Products</h3>
                    <Button variant="contained" color="error" onClick={() => deleteReel()} style={styles.deleteButton}>
                        <DeleteIcon />
                    </Button>
                </div>
                <DataGrid
                    onRowClick={(props) => {
                        navigate((isAdmin ? '/admin' : '') + "/products/edit/" + props.row._id)
                    }}
                    columns={columns}
                    rows={products}
                    initialState={{
                        pagination: {
                            paginationModel: { page: 0, pageSize: 5 },
                        },
                    }}
                    pageSizeOptions={[5, 10]}
                />
            </>

        }

    }
    return <>
        <BackButton />
        <Grid container spacing={2} padding={2}>
            <Grid item xs={12} md={3}>
                <Item>
                    <Box p={2}>
                        <VideoPlace reelData={reelData} />
                    </Box>
                </Item>
            </Grid>
            <Grid item xs={12} md={9}>
                <Item>
                    <Box p={2}>
                        <ListProducts products={products} />
                        {isAdmin ? '' : <AddProducts allProducts={allProducts} />}
                    </Box>
                </Item>
            </Grid>
        </Grid>
        <Modal open={deleteModalOpen} onClose={() => setDeleteModalOpen(false)}>
            {/* <Box sx={styles.modalBox}> */}
            <DeleteConfirmModal onClose={() => setDeleteModalOpen(false)} url={`${BASE_URL}/api/seller/deleteReel/${reelId}`} isAdmin={isAdmin} setShowSnackbar={setShowSnackbar} setSnackbarMessage={setSnackbarMessage} setSnackbarSeverity={setSnackbarSeverity} user={user} />
            {/* </Box> */}
        </Modal>
    </>

}
const styles = {
    deleteButton: {
        // marginBottom: '20px',
        // marginInline: '10px',
        position: 'absolute',
        right: "0px",
        top: '0px'
    },
}