import { useState, useEffect } from 'react';
import { Paper, Typography, Button, Grid, Divider ,Table, TableBody, TableCell, TableContainer, TableHead, TableRow,} from '@mui/material';
import { useParams } from 'react-router-dom';
import BackButton from './BackButton';

const dummyOrder = {
    id: 1,
    customerName: 'John Doe',
    orderDate: '2023-08-01',
    status: 'Processing',
    totalAmount: 150.0,
    shippingAddress: '123 Main St, City, Country',
    items: [
      { id: 101, name: 'Product A', quantity: 2, price: 50.0 },
      { id: 102, name: 'Product B', quantity: 3, price: 30.0 },
      { id: 103, name: 'Product C', quantity: 1, price: 70.0 },
    ],
  };
const OrderDetailsPage = ({ BASE_URL ,userID}) => {
  const { orderId } = useParams();
  const [order, setOrder] = useState(null);

  useEffect(() => {
    // Fetch order details based on orderId from your API
    const fetchOrderDetails = async () => {
      try {
        // Make an API request to get order details
        // const response = await fetch(`${BASE_URL}/api/orders/${orderId}`);
        // const data = await response.json();
        setOrder(dummyOrder);
      } catch (error) {
        console.error('Error fetching order details:', error);
      }
    };

    fetchOrderDetails();
  }, [BASE_URL, orderId]);

  // Loading state while fetching data
  if (!order) {
    return <div>Loading...</div>;
  }

  return (<>
  <BackButton/>
    <Paper elevation={3} style={styles.orderDetailsContainer}>
      <Typography variant="h5" gutterBottom>
        Order Details - #{order.id}
      </Typography>
      <Divider />
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Typography variant="subtitle1">Customer: {order.customerName}</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="subtitle1">Order Date: {order.orderDate}</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="subtitle1">Order Status: {order.status}</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="subtitle1">Total Amount: ${order.totalAmount.toFixed(2)}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle1">Shipping Address: {order.shippingAddress}</Typography>
        </Grid>
      </Grid>
      <Divider style={styles.divider} />
      <Typography variant="h6">Order Items:</Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Product Name</TableCell>
              <TableCell>Quantity</TableCell>
              <TableCell>Price</TableCell>
              <TableCell>Total</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {order.items.map((item) => (
                <TableRow key={item.id}>
                <TableCell>{item.name}</TableCell>
                <TableCell>{item.quantity}</TableCell>
                <TableCell>${item.price.toFixed(2)}</TableCell>
                <TableCell>${(item.quantity * item.price).toFixed(2)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Divider style={styles.divider} />
      <Button variant="contained" color="primary" onClick={() => alert('Mark as Shipped')}>
        Mark as Shipped
      </Button>
    </Paper>
            </>
  );
  
};

export default OrderDetailsPage;

const styles = {
  orderDetailsContainer: {
    padding: '20px',
    maxWidth: '800px',
    margin: 'auto',
  },
  divider: {
    margin: '20px 0',
  },
  // Add more styles as needed
};
