import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  List,
  ListItem,
  ListItemText,
  Divider,
  Collapse,
  ListItemIcon,
} from '@mui/material';
import InventoryIcon from '@mui/icons-material/Inventory';
import { AddBox, VideoLibrary, Person, HelpOutline, QueryBuilder, Feedback, } from '@mui/icons-material';
import FormatListNumberedRtlIcon from '@mui/icons-material/FormatListNumberedRtl';
import CollapsibleOption from '../CollapsibleOption';
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
import SellIcon from '@mui/icons-material/Sell';
import InsightsIcon from '@mui/icons-material/Insights';


const Sidebar = () => {
    const navigate=useNavigate()
  return (
    <>
      <List style={{ paddingTop: '64px', backgroundColor: "#f0f0f0",color: '#333',transition: "background-color 0.3s" }}>
        <CollapsibleOption style={styles.sideBarMenuItem} title={"Reels"} icon={<VideoLibrary/>} subCategories={[{title:'List',target:"/admin/reels/list",icon:<FormatListNumberedRtlIcon/>},]}/>
        <CollapsibleOption style={styles.sideBarMenuItem} title={"Products"} icon={<InventoryIcon/>} subCategories={[{title:'List',target:"/admin/products/list",icon:<FormatListNumberedRtlIcon/>},]}/>
        <CollapsibleOption style={styles.sideBarMenuItem} title={"Sells"} icon={<SellIcon/>} subCategories={[{title:'Orders',target:"/admin/sells/orders",icon:<ShoppingBagIcon/>},{title:'Stats',target:"/admin/sells/stats",icon:<InsightsIcon/>}]}/>
      <Divider />
      <CollapsibleOption style={styles.sideBarMenuItem} title={"Support"} icon={<HelpOutline/>} subCategories={[{title:'Feedbacks',target:"",icon:<Feedback/>},{title:'Queries',target:"",icon:<QueryBuilder/>}]}/>
      
      {/* </List>
      <List> */}
      </List>
    </>
  );
};

export default Sidebar;
const styles={
  sideBarMenuItem:{
    
  }
}
