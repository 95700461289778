import React, { useState, useEffect } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import {
  CssBaseline,
  AppBar,
  Toolbar,
  Typography,
  Drawer,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Container,
  Hidden,
} from '@mui/material';
import axios from 'axios'
import { ReelPage } from './reelSpecific';
import MenuIcon from '@mui/icons-material/Menu';
import Sidebar from '../components/admin/Sidebar';
import { Logout } from '@mui/icons-material';
import LoadingScreen from '../components/LoadingScreen';
import { getAuth ,onAuthStateChanged,signOut} from 'firebase/auth';
import ProductDetailsPage from '../components/ProductDetailPage';

import AdminOrderList from '../components/admin/AdminOrderList';
import UploadedProductsList from '../components/UploadedProducts';
import { ReelList } from './listReels';
import Stats from '../components/admin/Stats';
const drawerWidth = 240;
const AdminDashboard = ({
  BASE_URL,
  setShowSnackbar,
  setSnackbarMessage,
  setSnackbarSeverity,
}) => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const [user, setUser] = useState(null);
  const [userID,setUserID]=useState(null)
  const [isAdmin,setIsAdmin]=useState(false)
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, async(authUser) => {
      if (authUser) {
        // User is signed in
        setUser(authUser);
        try{
          var response = await axios.post(`${BASE_URL}/api/seller/create_session?phone=${authUser.phoneNumber}&uid=${authUser.uid}`)
          var seller = response.data.user

          // window.sessionStorage
          setUserID(seller._id)
          if(seller.isAdmin)
          setIsAdmin(true)
          setLoading(false);
          }
          catch(err){
              console.log(err);
              //snackbar to show failed !!!
          }
      } else {
        // User is signed out
        setUser(null);
        setLoading(false);
      }
    });

    return unsubscribe;
  }, []);
  const logout=async()=>{
    try{
      const auth = getAuth();
      await signOut(auth);
      setSnackbarMessage('Logged Outsuccessfully');
      setSnackbarSeverity('success');
      setShowSnackbar(true);
      setUserID(null)
    }
    catch(err){
      console.log(err)
      setSnackbarMessage('Something went wrong');
      setSnackbarSeverity('error');
      setShowSnackbar(true);
    }
  }

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  if(!user) { 
    navigate('/login')}
  if(!userID|| !user) return <LoadingScreen/>
  if(!isAdmin) { navigate('/');return <></>}

  return (
    <div style={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        style={{
          width: '100%',
          marginLeft: mobileOpen ? drawerWidth : 0,
          transition: 'margin 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
          zIndex: 1000000,
        }}
      >
        <Toolbar>
          <Hidden mdUp implementation="css">
            <IconButton
              color="inherit"
              aria-label="Open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              style={{
                marginRight: 16,
                display: mobileOpen ? 'none' : 'block',
              }}
            >
              <MenuIcon />
            </IconButton>
          </Hidden>
          <Typography variant="h6">Admin Dashboard</Typography>
          <div style={{ position: 'absolute', right: '10px', display: 'flex', flexWrap: 'nowrap' }}>
            <Typography variant="h6" style={{ marginTop: '4px' }}>
              Logout
            </Typography>
            <IconButton onClick={logout}>
              <Logout color="error" />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      <Hidden mdUp implementation="js">
        <Drawer
          variant="temporary"
          anchor="left"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{ keepMounted: true }}
          style={{ display: mobileOpen ? 'block' : 'none' }}
        >
          <div style={{ width: drawerWidth }}>
            {/* Render admin-specific sidebar */}
            <Sidebar isAdmin={true} />
          </div>
        </Drawer>
      </Hidden>
      <Hidden smDown implementation="css">
        <Drawer
          variant="permanent"
          style={{
            width: drawerWidth,
            flexShrink: 0,
            display: mobileOpen ? 'none' : 'block',
          }}
        >
          <div style={{ width: drawerWidth }}>
            {/* Render admin-specific sidebar */}
            <Sidebar isAdmin={true} />
          </div>
        </Drawer>
      </Hidden>
      <main
        style={{
          flexGrow: 1,
          padding: 16,
          marginLeft: mobileOpen ? drawerWidth : 0,
        }}
      >
        <div style={{ minHeight: 64 }} />
        <Container>
          <Routes>
            <Route
              index
              element={<Typography variant="h4">Welcome to the Admin Dashboard</Typography>}
            ></Route>
            <Route   path="reels"  >
              <Route path="list" element={<ReelList BASE_URL={BASE_URL}  userID={userID}  isAdmin={isAdmin} user={user} />} />
              <Route path='edit/:reelId' element={<ReelPage BASE_URL={BASE_URL}  userID={userID}  isAdmin={isAdmin} setShowSnackbar={setShowSnackbar} setSnackbarMessage={setSnackbarMessage} setSnackbarSeverity={setSnackbarSeverity} user={user} />} />
            </Route>
            <Route path="products">
                <Route path="list" element={<UploadedProductsList  BASE_URL={BASE_URL} userID={userID}  isAdmin={isAdmin} user={user} />} />
                <Route path='edit/:productId' element={<ProductDetailsPage BASE_URL={BASE_URL}  userID={userID} setShowSnackbar={setShowSnackbar} setSnackbarMessage={setSnackbarMessage} setSnackbarSeverity={setSnackbarSeverity} isAdmin={isAdmin} user={user}/>} />
            </Route>
            <Route path="sells">
              <Route path="orders" element={<AdminOrderList BASE_URL={BASE_URL}  isAdmin={isAdmin} user={user}/>}></Route>
              <Route path="stats" element={<Stats BASE_URL={BASE_URL}   isAdmin={isAdmin} user={user}/>}></Route>

            </Route>
            {/* Add other admin-related routes here */}
          </Routes>
        </Container>
      </main>
    </div>
  );
};

export default AdminDashboard;
