
import { useNavigate } from 'react-router-dom';
import { useState,useEffect } from 'react';
import { CheckCircle, HourglassEmpty, LocalShippingOutlined } from '@mui/icons-material';
import { Table, TableBody,Select, TableCell, TableContainer, TableHead, TableRow, Paper, Button,Box ,MenuItem} from '@mui/material';


const dummyOrders = [
    {
      id: 1,
      customerName: 'John Doe',
      orderDate: '2023-08-01',
      status: 'Processing',
    },
    {
      id: 2,
      customerName: 'Alice Smith',
      orderDate: '2023-08-05',
      status: 'Shipped',
    },
    {
      id: 3,
      customerName: 'Bob Johnson',
      orderDate: '2023-08-08',
      status: 'Delivered',
    },
    {
      id: 4,
      customerName: 'Eva Wilson',
      orderDate: '2023-08-12',
      status: 'Processing',
    },
    {
      id: 5,
      customerName: 'David Brown',
      orderDate: '2023-08-15',
      status: 'Shipped',
    },
  ];
  
const OrderTable = ({ BASE_URL,userID}) => {
  const navigate=useNavigate()
  const [orders,setOrders]=useState([])
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [orderStatusFilter, setOrderStatusFilter] = useState('All');
  const [sortOrder, setSortOrder] = useState('asc');

  useEffect(()=>{
    const fetchOrders=async()=>{
        setOrders(dummyOrders)
    }
    fetchOrders()
  },[userID])

  useEffect(() => {
    // Apply filters and sorting whenever "orders" or filter/sort states change
    let filtered = [...orders];

    // Apply the status filter if not "All"
    if (orderStatusFilter !== 'All') {
      filtered = filtered.filter((order) => order.status === orderStatusFilter);
    }

    // Sort the filtered orders by date
    filtered.sort((a, b) => {
      const dateA = new Date(a.orderDate);
      const dateB = new Date(b.orderDate);
      if (sortOrder === 'asc') {
        return dateA - dateB;
      } else {
        return dateB - dateA;
      }
    });

    setFilteredOrders(filtered);
  }, [orders, orderStatusFilter, sortOrder]);


  return (<>
      <div style={styles.tableContainer}>
        <Select 
          value={orderStatusFilter}
          onChange={(e) => setOrderStatusFilter(e.target.value)}
        >
          <MenuItem value="All">All</MenuItem>
          <MenuItem value="Processing">Processing</MenuItem>
          <MenuItem value="Shipped">Shipped</MenuItem>
          <MenuItem value="Delivered">Delivered</MenuItem>
        </Select>

        <Select
          value={sortOrder}
          onChange={(e) => setSortOrder(e.target.value)}
        >
          <MenuItem value="asc">Sort by Date (Ascending)</MenuItem>
          <MenuItem value="desc">Sort by Date (Descending)</MenuItem>
        </Select>
      </div>
    <TableContainer component={Paper} style={styles.tableContainer} >
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Order ID</TableCell>
            <TableCell>Customer</TableCell>
            <TableCell>Order Date</TableCell>
            <TableCell>Order Status</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredOrders.map((order) => (
            <TableRow key={order.id}>
              <TableCell>{order.id}</TableCell>
              <TableCell>{order.customerName}</TableCell>
              <TableCell>{order.orderDate}</TableCell>
              <TableCell>
              <Box display="flex" alignItems="center">
                {order.status === 'Processing' && (
                  <HourglassEmpty style={{ color: 'orange' }} />
                  )}
                {order.status === 'Shipped' && (
                  <LocalShippingOutlined style={{ color: 'blue' }} />
                  )}
                {order.status === 'Delivered' && (
                  <CheckCircle style={{ color: 'green' }} />
                  )}
                <span>{order.status}</span>
              </Box>
                </TableCell>
              <TableCell>
                <Button variant="outlined" onClick={() => navigate(`/sells/order/${order?.id}`)}>
                  View Details
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
</>
  );
};

export default OrderTable;
const styles ={
  tableContainer: {
    maxWidth: '800px',
      margin: 'auto',
    },
    // Add more styles as needed
  }