import React, { useState } from 'react';
import axios from 'axios'
import { useParams, useNavigate } from 'react-router-dom';
import { Button, TextField, Select, MenuItem, FormControl, InputLabel, Box, Typography, Modal, Backdrop, Fade } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
const DeleteConfirmModal = ({ onClose, setShowSnackbar, setSnackbarMessage, setSnackbarSeverity,url,user,isAdmin }) => {
    
    const navigate=useNavigate();
    let type=(url.includes('Reel'))?'Reel':'Product';
    const handleSubmit = async () => {
        try {
            const headers = {
                Authorization: `Bearer ${user.accessToken}`,
              };
              
            // Make API request to upload resource and associate with variant
            await axios.delete(url,{headers});
            setSnackbarMessage(`${type} Deleted successfully`);
            setSnackbarSeverity('success');
            setShowSnackbar(true);
            setTimeout(()=>{navigate((isAdmin?'/admin':'')+`/${type?.toLowerCase()}s/list`);},1000)

            onClose(); // Close the modal after successful upload
        } catch (error) {
            setSnackbarMessage('Error in deletion');
            setSnackbarSeverity('error');
            setShowSnackbar(true);
            console.error('Error ', error);
        }
    };

    return (
        <Modal
            open={true}
            onClose={onClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={true}>
                <Box sx={styles.modalContent}>
                    <Typography variant="h6" gutterBottom>
                        Confirm Deletion
                    </Typography>
                    <p style={{ width: '100%', color: 'red', marginTop: '5px' }}>Are you sure you want to delete this {type} ?</p>
                    <div style={{ width: '100%', display: 'flex' }}>
                        <Button variant="contained" color="error" onClick={handleSubmit} style={styles.Button}>
                            Delete
                        </Button>
                        <Button variant="outlined" color="primary" onClick={onClose} style={styles.Button}>
                            Cancel
                        </Button>
                    </div>
                </Box>
            </Fade>
        </Modal>
    );
};

const styles = {
    modalContent: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'white',
        boxShadow: 24,
        p: 4,
        outline: 'none',
        width: '300px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '20px',
    },

    Button: {
        width: 'fit-content',
        margin: 'auto'
    },
};

export default DeleteConfirmModal;
