import React, { useState, useEffect } from 'react';
import { Card, CardContent, Typography,Grid } from '@mui/material';

const AnimatedCounter = ({ label,targetValue,color }) => {
  const [counterValue, setCounterValue] = useState(0);

  useEffect(() => {
    let animationFrameId;
    const step = Math.ceil(targetValue / 100); // Adjust step size as needed for smoother/faster animation

    const animateCounter = () => {
      if (counterValue < targetValue) {
        setCounterValue((prevValue) => Math.min(prevValue + step, targetValue));
        animationFrameId = requestAnimationFrame(animateCounter);
      }
      else{
        setCounterValue(targetValue)
      }
    };

    animateCounter();

    return () => {
      cancelAnimationFrame(animationFrameId);
    };
  }, [counterValue, targetValue]);

  return (
    <Grid item xs={12} sm={6} md={3} >
    <Card style={{backgroundColor:color}}>
      <CardContent>
        <Typography variant="h6">{label}</Typography>
        <Typography variant="h4">{counterValue}</Typography>
      </CardContent>
    </Card>
    </Grid>
  );
};

export default AnimatedCounter;
