import React, { useState, useEffect } from 'react';
import { CardMedia } from '@mui/material';
import { BASE_URL } from '..';
import { fetchResource } from '..';
function Video({ VideoUrl,height,sx}) {
  if(!VideoUrl) return <></>
  const temp=VideoUrl.split('/');
  const key=temp[temp.length-1]
  var url=`${BASE_URL}/api/seller/resource?type=video&name=${key}`

  return (
     <video src={url} type="video/mp4" width="100%" height="auto" controls /> 
  );
}

export default Video;
