import React, { useState, useEffect } from 'react';
import { CardMedia } from '@mui/material';
import { useQuery } from 'react-query';
import { fetchResource } from '..';
function Image({ imageUrl,height,sx}) {
  function useImageCache(imageUrl) {
    return useQuery(['image', imageUrl], () => fetchResource("image",imageUrl), {
      staleTime: Infinity, // Cache images indefinitely
    });
  }
  const {data:cachedImage}=useImageCache(imageUrl)
  // useEffect(() => {
  //   const fetchImage=async()=>{
  //       let url=await fetchResource("image",imageUrl);
  //       setImage(url)
  //   }
    
  //   fetchImage()
  // }, [imageUrl]);

  return (
    <CardMedia
        component="img"
        height={height?height:''}
        sx={{...sx}}
        image={cachedImage}
        alt={"image"}
    />
  );
}

export default Image;
