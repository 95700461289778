import React, { useEffect,useState } from 'react';
import {
  Box,
  Typography,
  Grid,
  Container,
  Paper,
  Button,
  Link,
  ThemeProvider,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { createTheme } from '@mui/material/styles';
import StoreIcon from '@mui/icons-material/Store';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import BusinessIcon from '@mui/icons-material/Business';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import TwitterIcon from '@mui/icons-material/Twitter';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import EditIcon from '@mui/icons-material/Edit';
import axios from 'axios';

const theme = createTheme({
  palette: {
    primary: {
      main: '#007acc',
    },
    secondary: {
      main: '#ff9100',
    },
  },
});

const styles = {
  container: {
    padding: '20px',
    marginBottom: '20px',
    backgroundColor: '#f0f0f0',
  },
  heading: {
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
    padding: '10px 20px',
    marginBottom: '20px',
    textAlign: 'center',
  },
  content: {
    padding: '20px',
  },
  section: {
    marginBottom: '20px',
  },
  icon: {
    fontSize: '24px',
    marginRight: '10px',
    verticalAlign: 'middle',
  },
  socialIcons: {
    display: 'flex',
    justifyContent: 'center',
    gap: '10px',
    marginTop: '10px',
  },
};



const SellerProfile = ({BASE_URL,userID}) => {
  const[profileData,setProfileData]=useState({})
  const navigate=useNavigate()
  useEffect(()=>{
    const fetchData = async () => {
      try {
        const storeInfoResponse = await axios.get(`${BASE_URL}/api/seller/getStoreInfo/${userID}`);
        const businessInfoResponse = await axios.get(`${BASE_URL}/api/seller/getBusinessInfo/${userID}`);
        const socialMediaResponse = await axios.get(`${BASE_URL}/api/seller/getSocialMedia/${userID}`);
    
        const storeInfo = storeInfoResponse.data;
        const businessInfo = businessInfoResponse.data;
        const socialMedia = socialMediaResponse.data;
    
        // Combine the data into one object
        const Data = {
          storeName: storeInfo.storeName || '',
          phoneNumber: storeInfo.phoneNumber || '',
          email: storeInfo.email || '',
          storeAddress: storeInfo.storeAddress || '',
          companyName: businessInfo.companyName || '',
          gstin: businessInfo.gstin || '',
          acceptedTerms: businessInfo.acceptedTerms || false,
          twitter: socialMedia.twitter || '',
          facebook: socialMedia.facebook || '',
          instagram: socialMedia.instagram || '',
        };
    
        setProfileData(Data);
      } catch (error) {
        console.error('Error fetching profile data:', error);
        return null; // You can handle errors as needed
      }
    };

    fetchData()
    
  },[userID])
  
  return (
    <ThemeProvider theme={theme}>
      <Container>
        <Paper elevation={3} style={styles.container}>
          <Typography variant="h5" style={styles.heading}>
            <StoreIcon style={styles.icon} />
            Seller Profile
          </Typography>
          <Box style={styles.content}>
            <div style={styles.section}>
              <Typography variant="h6">
                <StoreIcon style={styles.icon} />
                Store Information
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle1">
                    <StoreIcon style={styles.icon} />
                    Store Name: {profileData.storeName}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle1">
                    <PhoneIcon style={styles.icon} />
                    Phone Number: {profileData.phoneNumber}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle1">
                    <EmailIcon style={styles.icon} />
                    Email: {profileData.email}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle1">
                    <LocationOnIcon style={styles.icon} />
                    Store Address: {profileData.storeAddress}
                  </Typography>
                </Grid>
              </Grid>
            </div>
            <div style={styles.section}>
              <Typography variant="h6">
                <BusinessIcon style={styles.icon} />
                Business Information
              </Typography>
              <Typography variant="subtitle1">
                <BusinessIcon style={styles.icon} />
                Company Name: {profileData.companyName}
              </Typography>
              <Typography variant="subtitle1">
                <AccountBalanceIcon style={styles.icon} />
                GSTIN: {profileData.gstin}
              </Typography>
              <Typography variant="subtitle1">
                <CheckCircleIcon style={styles.icon} />
                Accepted Terms: {profileData.acceptedTerms ? 'Yes' : 'No'}
              </Typography>
            </div>
            <div style={styles.section}>
              <Typography variant="h6">
                <TwitterIcon style={styles.icon} />
                Social Media Information
              </Typography>
              <Box style={styles.socialIcons}>
                <Link href={profileData.twitter}>
                  <Button variant="contained" color="primary">
                    Twitter
                  </Button>
                </Link>
                <Link href={profileData.facebook}>
                  <Button variant="contained" color="primary">
                    Facebook
                  </Button>
                </Link>
                <Link href={profileData.instagram}>
                  <Button variant="contained" color="primary">
                    Instagram
                  </Button>
                </Link>
              </Box>
            </div>
            <Button onClick={()=>{navigate('edit')}}><EditIcon/>Edit</Button>
          </Box>
        </Paper>
      </Container>
    </ThemeProvider>
  );
};

export default SellerProfile;
