import React, { useEffect, useState } from 'react';
import { Typography, Card, CardContent, Grid, Paper } from '@mui/material';
import { Bar,Line } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    PointElement,
    LineElement,
    Legend,
  } from 'chart.js';
import AnimatedCounter from '../AnimatedCounter';
  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  );
export const options = {
    indexAxis: 'y' ,
    elements: {
      bar: {
        borderWidth: 2,
      },
    },
    responsive: true,
    plugins: {
      legend: {
        
        position: 'right' ,
        display:false
      },
      title: {
        display: true,
        text: 'Top Selling Products',
      },
      
    },
  };
const backgroundColors = [
'rgba(75, 192, 192, 0.6)',
'rgba(255, 99, 132, 0.6)',
'rgba(54, 162, 235, 0.6)',
'rgba(255, 206, 86, 0.6)',
'rgba(153, 102, 255, 0.6)',
];

const getRandomColor = () => {
// Generate a random index to select a color from the array
const randomIndex = Math.floor(Math.random() * backgroundColors.length);
return backgroundColors[randomIndex];
};
const lineoptions={
    responsive: true,
            maintainAspectRatio: false,
            scales: {
              y: {
                beginAtZero: true,
              },
            },
}
const Stats = ({isAdmin,user,userID}) => {
    const [statistics, setStatistics] = useState({
        totalProducts: 0,
        totalReels: 0,
        avgProductsPerSeller: 0,
        avgReelsPerSeller: 0,
        totalSeller:0,
        totalOrders: 0,
        totalRevenue: 0,
        avgOrderValue: 0,
        salesTrends: {
        daily: [],
        weekly: [],
        monthly: [],
        },
        labels:{
        daily: [],
        weekly: [],
        monthly: [],
        },
        topProd:{
            label:[],
            data:[]
        }
    });

    const [topSellingData, setTopSellingData] = useState({
        labels: [],
        datasets: [
            {
                data: [],
                backgroundColor: [],
            },
        ],
    });

    useEffect(() => {
        const fetchStatistics = async () => {

            try {

                // const topSellingResponse = await fetch('/api/topSelling');
                setStatistics({
                    totalProducts: 8000,
                    totalReels: 400,
                    avgProductsPerSeller: 21.9,
                    avgReelsPerSeller: 12.6,
                    totalSeller:12,
                    totalOrders: 150,
                    totalRevenue: 35000,
                    avgOrderValue: 233.33,
                    salesTrends: {
                        daily: [100, 120, 90, 110, 130, 150, 140],
                        weekly: [750, 850, 900, 950, 800, 700, 720],
                        monthly: [2400, 2800, 2900, 3100, 3200, 3100, 3000],
                    },
                    labels:{
                        daily:['06-09-23','07-09-23','08-09-23','09-09-23','10-09-23','11-09-23','12-09-23',],
                        weekly:['16 aug - 22 aug','23 aug - 29 aug','30 aug - 5 sept','6 sept - 12 sept'],
                        monthly:[]
                    },
                    topProd:{
                        label:['Product A', 'Product B', 'Product C', 'Product D', 'Product E'],
                        data: [300, 280, 200, 150, 120]
                    }
                    ,
                })
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchStatistics();
    }, []);

    return (
        <div>
            <Typography variant="h4" gutterBottom>
                Selling Stats
            </Typography>
            <Grid container spacing={3}>      
                    <AnimatedCounter color={getRandomColor()} label={"Total Products Listed"} targetValue={statistics.totalProducts}/>
                    <AnimatedCounter color={getRandomColor()} label={"Total Reels Posted"} targetValue={statistics.totalReels}/>
                    <AnimatedCounter color={getRandomColor()} label={"Avg Products / Seller"} targetValue={statistics.avgProductsPerSeller.toFixed(2)}/>
                    <AnimatedCounter color={getRandomColor()} label={"Avg Reels / Seller"} targetValue={statistics.avgReelsPerSeller.toFixed(2)}/>
            </Grid>
            <br />
            <Grid container spacing={3}>      
                    <AnimatedCounter color={getRandomColor()} label={"Total Sellers"} targetValue={statistics.totalSeller}/>
                    <AnimatedCounter color={getRandomColor()} label={"Total Order Placed"} targetValue={statistics.totalOrders}/>
                    <AnimatedCounter color={getRandomColor()} label={"Total Sales"} targetValue={statistics.totalRevenue}/>
                    <AnimatedCounter color={getRandomColor()} label={"Avg Order Value"} targetValue={statistics.avgOrderValue.toFixed(2)}/>
            </Grid>
            <Paper elevation={3} style={{ marginTop: '20px', padding: '20px' }}>
            <Line
          data={{
            labels: statistics.labels.daily,
            datasets: [
              {
                label: 'Daily Sales Trends',
                data: statistics.salesTrends.daily,
                borderColor: 'rgba(75, 192, 192, 0.6)',
                backgroundColor: 'rgba(75, 192, 192, 0.2)',
              },
            ],
          }}
          options={lineoptions}
        />
      </Paper>
      <Paper elevation={3} style={{ marginTop: '20px', padding: '20px' }}>
        <Line
          data={{
            labels: statistics.labels.weekly,
            datasets: [
              {
                label: 'Weekly Sales Trends',
                data: statistics.salesTrends.weekly,
                borderColor: 'rgba(255, 99, 132, 0.6)',
                backgroundColor: 'rgba(255, 99, 132, 0.2)',
              },
            ],
          }}
          options={lineoptions}
        />
      </Paper>
      <Paper elevation={3} style={{ marginTop: '20px', padding: '20px' }}>
        <Line
          data={{
            labels: ['Month 1', 'Month 2', 'Month 3', 'Month 4', 'Month 5', 'Month 6', 'Month 7'],
            datasets: [
              {
                label: 'Monthly Sales Trends',
                data: statistics.salesTrends.monthly,
                borderColor: 'rgba(54, 162, 235, 0.6)',
                backgroundColor: 'rgba(54, 162, 235, 0.2)',
              },
            ],
          }}
          options={lineoptions}
        />
      </Paper>
            <Paper elevation={3} style={{ marginTop: '20px', padding: '20px' }}>
            <Bar
                    data={{
                        labels: statistics.topProd.label,
                        datasets: [
                        {
                            data: statistics.topProd.data, 
                            backgroundColor: [
                            'rgba(75, 192, 192, 0.6)',
                            'rgba(255, 99, 132, 0.6)',
                            'rgba(54, 162, 235, 0.6)',
                            'rgba(255, 206, 86, 0.6)',
                            'rgba(153, 102, 255, 0.6)',
                            ],
                        },
                    ],
                    }
                    }
                    options={options}
         />
            </Paper>
        </div>
    );
};

export default Stats;
