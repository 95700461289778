import React, { useState, useEffect } from 'react';
import { Card, Box, TextField, Button, Checkbox, FormControlLabel, Typography, Snackbar ,LinearProgress} from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import BackButton from './BackButton';
import axios from 'axios';
import LoadingScreen from './LoadingScreen';

const VideoUploadForm = ({ userID, BASE_URL, setShowSnackbar, setSnackbarMessage, setSnackbarSeverity ,isAdmin,user}) => {
    const [products, setProducts] = useState([])
    const [Loading,setLoading]=useState(false)
    const [uploadProgress, setUploadProgress] = useState(0);

    const [videoInfo, setVideoInfo] = useState({
        title: '',
        description: '',
        videoFile: null,
        selectedProducts: [],
    });
    useEffect(() => {
        async function fetchUploadedProducts() {
            try {
                const headers = {
                    Authorization: `Bearer ${user.accessToken}`,
                  };
                const response = await axios.get(`${BASE_URL}/api/seller/uploadedProducts/${userID}`,{headers});
                setProducts(response.data.products);
            } catch (error) {
                console.error('Error fetching uploaded products:', error);
            }
        }

        fetchUploadedProducts();
    }, []);

    const handleChange = (e) => {
        const { name, value, files, type, checked } = e.target;
        if (name === 'videoFile') {
            setVideoInfo((prevData) => ({
                ...prevData,
                videoFile: files[0],
            }));
        } else if (type === 'checkbox') {
            const selectedProducts = [...videoInfo.selectedProducts];
            if (checked) {
                selectedProducts.push(value);
            } else {
                const index = selectedProducts.indexOf(value);
                if (index !== -1) {
                    selectedProducts.splice(index, 1);
                }
            }
            setVideoInfo((prevData) => ({
                ...prevData,
                selectedProducts,
            }));
        } else {
            setVideoInfo((prevData) => ({
                ...prevData,
                [name]: value,
            }));
        }
        setShowSnackbar(false);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {

            if (!videoInfo.videoFile) {
                setSnackbarMessage('No Video Selected. Please select one. If the problem persists try after reloading the page');
                setSnackbarSeverity('error');
                setShowSnackbar(true);
                return
            }
            const formData = new FormData();
            formData.append('title', videoInfo.title);
            formData.append('description', videoInfo.description);
            videoInfo.selectedProducts.map((product) => {
                formData.append('selectedProducts', product);
            })
            formData.append('videoFile', videoInfo.videoFile);
            setLoading(true)
            
            const config = {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${user.accessToken}`,
                },
                onUploadProgress: (progressEvent) => {
                    const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    setUploadProgress(percentCompleted);
                },
            };
            await axios.post(`${BASE_URL}/api/seller/uploadVideo/${userID}`, formData, config);
            setLoading(false)
            setSnackbarMessage('Video uploaded successfully');
            setSnackbarSeverity('success');
            setShowSnackbar(true);

            // Clear form fields after successful upload
            setVideoInfo({
                title: '',
                description: '',
                videoFile: null,
                selectedProducts: [],
            });
            setUploadProgress(0);
        } catch (error) {
            console.error('Error uploading video:', error);
            setSnackbarMessage('Error uploading video');
            setSnackbarSeverity('error');
            setShowSnackbar(true);
            setLoading(false)
        }
    };
    useEffect(()=>{},[userID])
    if(!userID) return <LoadingScreen></LoadingScreen>

    return (
        <Card style={{ maxWidth: 1000, margin: 'auto', marginTop: '50px' }} variant="outlined" align="left">
            <Box p={2} >
                <Typography variant="h5">Upload Video</Typography>
                {uploadProgress > 0 && (
                    <div style={{display:'flex',}}>
                    <h6 style={{width:'5%'}}>{uploadProgress}</h6>
                    <LinearProgress
                        variant="determinate"
                        value={uploadProgress}
                        style={{ marginTop: '16px',width:'90%' }}
                        />
                    </div>
                    )}
                <TextField
                    id="title"
                    name="title"
                    label="Title"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    value={videoInfo.title}
                    onChange={handleChange}
                />
                <TextField
                    id="description"
                    name="description"
                    label="Description"
                    variant="outlined"
                    fullWidth
                    multiline
                    rows={4}
                    margin="normal"
                    value={videoInfo.description}
                    onChange={handleChange}
                />
                <label htmlFor="videoFile">
                    <input
                        type="file"
                        id="videoFile"
                        name="videoFile"
                        accept="video/*"
                        style={{ display: 'none' }}
                        onChange={handleChange}
                    />
                    <Button
                        variant="contained"
                        color="secondary"
                        component="span"
                        startIcon={<CloudUploadIcon />}
                    >
                        Upload Video
                    </Button>
                </label>
                <Box mt={2} mb={1}>
                    <Typography variant="h6">Select Products Shown in the Video:</Typography>
                    {products.map((product) => {

                        return <FormControlLabel
                            control={
                                <Checkbox
                                    name={product.name}
                                    value={product._id}
                                    checked={videoInfo.selectedProducts.includes(product._id)}
                                    onChange={handleChange}
                                />
                            }
                            label={product.name}
                        />
                    })}

                </Box>
                <Box m={2} align="center">
                    <Button variant="contained" disabled={Loading} onClick={handleSubmit}>
                        Upload
                    </Button>
                </Box>
            </Box>

        </Card>
    );
};

export default VideoUploadForm;
