import React, { useState } from 'react';
import { Button, TextField, Select, MenuItem, FormControl, InputLabel, Box, Typography, Modal, Backdrop, Fade } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import axios from 'axios';
const AddVariantForm = ({ onClose, variants, setVariants, productId, userID,isAdmin,user, BASE_URL, setShowSnackbar, setSnackbarMessage, setSnackbarSeverity }) => {

    const [Size, setSize] = useState(null)
    const [Color, setColor] = useState(null)
    const [Stock, setStock] = useState(0)
    const [Sizes, setSizes] = useState(["None", "S", "M", "L", "XL", "XXL", "7", "8", "9", "10", 'small', 'medium', 'large',])
    const [Colors, setColors] = useState(["None", 'Red', 'Blue', 'Green', 'Yellow', 'Orange', 'Purple', 'Pink', 'Brown', 'Gray', 'Teal',
    ])

    //fetch initial sizes and colors


    const handleSubmit = async () => {
        try {
            console.log(user.accessToken)
            const headers={
            Authorization: `Bearer ${user.accessToken}`,
            }
            await axios.post(`${BASE_URL}/api/seller/addVariant`, {size: Size, color: Color, stock: Stock, prodId: productId },{headers});
            setSnackbarMessage('New variant added successfully');
            setSnackbarSeverity('success');
            setShowSnackbar(true);
            setVariants([...variants, { size: Size, color: Color, stock: Stock, prodId: productId }])
            onClose(); // Close the modal after successful upload
        } catch (error) {
            setSnackbarMessage('Error in adding new variant');
            setSnackbarSeverity('error');
            setShowSnackbar(true);
            console.error('Error uploading resource:', error);
        }
    };

    return (
        <Modal
            open={true}
            onClose={onClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={true}>
                <Box sx={styles.modalContent}>
                    <Typography variant="h6" gutterBottom>
                        Add Variant
                    </Typography>

                    {/* <input type="file" accept="image/*" onChange={} style={styles.fileInput} /> */}
                    <FormControl variant="outlined" style={styles.variantSelect}>
                        <InputLabel>Choose Size</InputLabel>
                        <Select
                            value={Size}
                            onChange={(e) => setSize(e.target.value)}
                        >
                            {Sizes.map((size) => (
                                <MenuItem key={size} value={size}>
                                    <span>{size}</span>
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl variant="outlined" style={styles.variantSelect}>
                        <InputLabel>Choose Color</InputLabel>
                        <Select
                            value={Color}
                            onChange={(e) => setColor(e.target.value)}
                        >
                            {Colors.map((color) => (
                                <MenuItem key={color} value={color}>
                                    <span>{color}</span>
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl variant="outlined" style={styles.variantSelect}>
                        <TextField variant="outlined" placeholder='No of Stocks' type='number' inputProps={{ inputMode: 'numeric' }} onChange={(e) => setStock(e.target.value)}></TextField>

                    </FormControl>
                    <Button variant="contained" color="primary" onClick={handleSubmit} style={styles.uploadButton}>
                        Add
                    </Button>
                    <Button variant="outlined" color="primary" onClick={onClose} style={styles.cancelButton}>
                        Cancel
                    </Button>
                </Box>
            </Fade>
        </Modal>
    );
};

const styles = {
    modalContent: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'white',
        boxShadow: 24,
        p: 4,
        outline: 'none',
        width: '300px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '20px',
    },
    fileInput: {
        width: '100%',
    },
    variantSelect: {
        width: '100%',
    },
    uploadButton: {
        width: '100%',
    },
    tagname: {
        color: 'gray',
        marginInline: '4px',
    },
    cancelButton: {
        marginTop: '10px',
    },
};

export default AddVariantForm;
