import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { fetchResource } from '..';
import { Card, CardContent, Typography, Button, Modal, Box, Container, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, CardMedia } from '@mui/material';
import ResourceUploadForm from './ResourceUploadForm';
import DeleteConfirmModal from './DeleteConfirmModal';
import DeleteIcon from '@mui/icons-material/Delete';
import AddVariantForm from './AddVariantModal';
import { Carousel } from "react-responsive-carousel";

import "react-responsive-carousel/lib/styles/carousel.min.css";
import BackButton from './BackButton';
import LoadingScreen from './LoadingScreen';
import Image from './Image';
const ProductDetailsPage = ({ userID, BASE_URL, setShowSnackbar, setSnackbarMessage, setSnackbarSeverity,isAdmin,user }) => {
    const { productId } = useParams();
    const navigate = useNavigate();
    const [product, setProduct] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);
    const [modal2Open, setModal2Open] = useState(false);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [SelectedColor, setSelectedColor] = useState('');
    const [resources, setResources] = useState([]);
    const [variants, setVariants] = useState([])
    const [refresh, setRefresh] = useState(false);
    const [baseImage,setBaseImage]=useState(null)
    const [sellerName,setSellerName]=useState(null)
    useEffect(() => {
        async function fetchProduct() {
            try {
                const headers = {
                    Authorization: `Bearer ${user.accessToken}`,
                  };
                const response = await axios.get(`${BASE_URL}/api/seller/product/${productId}`,{headers});
                setProduct(response.data);
                setVariants(response.data.variants);
                setResources(response.data.resources); // Assuming resources are part of the product data
            } catch (error) {
                    setSnackbarMessage('Failed to fetch product. Try again later');
                    setSnackbarSeverity('error');
                    setShowSnackbar(true);
                    setTimeout(()=>{navigate((isAdmin?'/admin':'')+'/products/list');},1000)

        }}

        fetchProduct();
    }, [productId, refresh]);
    useEffect(()=>{
        const fetchSellerName =async()=>{
            try {
                const response = await axios.get(`${BASE_URL}/api/seller/getStoreInfo/${product?.sellerid}`);
                setSellerName(response?.data?.storeName);
            } catch (error) {
                console.error('Error fetching store information:', error);
            }

        }
        if(isAdmin)
        fetchSellerName()
    },[productId])

    const handleAddResource = (variantId) => {
        setSelectedColor(variantId);
        setModalOpen(true);
    };
    const addNewVariant = () => {
        setModal2Open(true);
    };
    const deleteProduct = () => {
        setDeleteModalOpen(true)
    }

    const handleDeleteResource = async (resourceId) => {
        try {
            // Make API request to delete the resource by its ID
            const headers = {
                Authorization: `Bearer ${user.accessToken}`,
              };
            await axios.delete(`${BASE_URL}/api/seller/resource/${resourceId}`,{headers});

            // Remove the deleted resource from the resources list
            setResources(resources.filter(resource => resource._id !== resourceId));
            setSnackbarMessage('Deleted');
            setSnackbarSeverity('success');
            setShowSnackbar(true);
            navigate('/products/list')
        } catch (error) {
            setSnackbarMessage('Error in deletion');
            setSnackbarSeverity('error');
            setShowSnackbar(true);
            console.error('Error deleting resource:', error);
        }
    };
    useEffect(()=>{},[userID])
    if(!product || !userID)
    return <LoadingScreen/>
    return (<>
            <BackButton/>
        <Container maxWidth="md" style={styles.container}>
            <h1>Product Details</h1>
            <Card variant="outlined" style={styles.card}>
                <div className="slider-container" style={{ width: "100%", maxWidth: '300px', margin: 'auto', backgroundSize: 'cover', aspectRatio: 1 }}>
                    <Carousel
                            showArrows={true}
                            infiniteLoop={true}
                            autoPlay={false}
                            showThumbs={false}
                            >
                        <Image imageUrl={product.image} sx={{ width: "100%", maxWidth: '300px', margin: 'auto', backgroundSize: 'cover', aspectRatio: 1 }}/>
                        {resources.map((event, index) => (
                            <div key={index}>
                                <Image imageUrl={event.url} sx={{ width: "100%", maxWidth: '300px', margin: 'auto', backgroundSize: 'cover', aspectRatio: 1 }}/>
                            </div>
                        ))}
                    </Carousel>
                </div>

                <CardContent sx={{ margin: 'auto' }}>
                    <Typography variant="h5" style={{color:'steelblue'}}>{product.name}</Typography>
                    {sellerName&&isAdmin && (
                    <Typography variant="body2">
                    <span style={{ background: 'linear-gradient(to right, #FF5733, #FFD633)', padding: '2px 6px' , borderRadius: '4px' }}>{sellerName}</span>
                    </Typography>
                    )}
                    <Typography variant="body1">{product.description}</Typography>
                    {/* <Typography variant="body2">Category: {product.category}</Typography> */}
                    <Typography variant="body2" style={{fontSize:17,color:'InactiveCaptionText'}} >Price: <span style={{color:'black',...styles.prize}}>{product.price}</span> Rs. </Typography>
                    {/* <Typography variant="body2">Ratings: {product.ratings}</Typography> */}
                </CardContent>
            </Card>
            <div style={{ display: 'flex' }}>

                <Button variant="contained" color="primary" onClick={() => handleAddResource('variantIdHere')} style={styles.addButton}>
                    Add Resource
                </Button>
                <Button variant="contained" color="primary" onClick={() => addNewVariant()} style={styles.addButton}>
                    Add Variants
                </Button>
                <Button variant="contained" color="error" onClick={() => deleteProduct()} style={styles.addButton}>
                    <DeleteIcon />
                </Button>
            </div>
            <TableContainer component={Paper}>
                <Table aria-label="resource table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Resource Thumbnail</TableCell>

                            <TableCell>Color</TableCell>
                            <TableCell>Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {resources.map((resource) => {
                            return (
                                <TableRow key={resource._id}>
                                    <TableCell>
                                        <Image imageUrl={resource.url} sx={styles.thumbnail}/>
                                        {/* <img src={resource.url} alt="Thumbnail" style={styles.thumbnail} /> */}
                                    </TableCell>
                                    <TableCell >{resource.color}</TableCell>
                                    <TableCell>
                                        <IconButton onClick={() => handleDeleteResource(resource._id)} aria-label="delete">
                                            <DeleteIcon />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>


            <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
                {/* <Box sx={styles.modalBox}> */}
                <ResourceUploadForm onClose={() => setModalOpen(false)} BASE_URL={BASE_URL}  variants={variants} productId={productId} SelectedColor={SelectedColor} setSelectedColor={setSelectedColor}   userID={userID} isAdmin={isAdmin} user={user} setShowSnackbar={setShowSnackbar} setSnackbarMessage={setSnackbarMessage} setSnackbarSeverity={setSnackbarSeverity} />
                {/* </Box> */}
            </Modal>
            <Modal open={modal2Open} onClose={() => setModal2Open(false)}>
                {/* <Box sx={styles.modalBox}> */}
                <AddVariantForm onClose={() => setModal2Open(false)} variants={variants} productId={productId} BASE_URL={BASE_URL}  userID={userID} isAdmin={isAdmin} user={user}  setShowSnackbar={setShowSnackbar} setSnackbarMessage={setSnackbarMessage} setSnackbarSeverity={setSnackbarSeverity} setVariants={setVariants} />
                {/* </Box> */}
            </Modal>
            <Modal open={deleteModalOpen} onClose={() => setDeleteModalOpen(false)}>
                {/* <Box sx={styles.modalBox}> */}
                <DeleteConfirmModal onClose={() => setDeleteModalOpen(false)} url={`${BASE_URL}/api/seller/deleteProduct/${productId}`} isAdmin={isAdmin} user={user}    productId={productId} setShowSnackbar={setShowSnackbar} setSnackbarMessage={setSnackbarMessage} setSnackbarSeverity={setSnackbarSeverity} />
                {/* </Box> */} 
            </Modal>
        </Container>
</>
    );
};

const styles = {
    container: {
        marginTop: '20px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    card: {
        marginBottom: '20px',
        width: "80%",
        display: 'flex',
        flexWrap: 'wrap',
        boxShadow:'0 3px',
        cursor:'pointer'
    },
    addButton: {
        marginBottom: '20px',
        marginInline: '10px'
    },
    modalBox: {
        width: 400,
        bgcolor: 'background.paper',
        p: 2,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },

    thumbnail: {
        width: '80px', // Updated width
        height: '80px', // Updated height
        objectFit: 'cover',
    },
    prize:{
        fontWeight:'bold',
        fontSize:20,
        // color:''
      }
};

export default ProductDetailsPage;
